<script>
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      valid: true,
      form: {},

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  async created() {
    if (this.id !== 'new') {
      this.form = await this.fetch();
      delete this.form.id;
      delete this.form.__typename;
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return false;
      if (this.id === 'new') this.insert();
      else this.update();
    },

    async insert() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($cultivar: cultivars_insert_input!) {
              insert_cultivars_one(object: $cultivar) {
                id
              }
            }
          `,
          variables: {
            cultivar: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/cultivares');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $cultivar: cultivars_set_input!) {
              update_cultivars_by_pk(pk_columns: { id: $id }, _set: $cultivar) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            cultivar: this.form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push('/cultivares');
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query Customers($id: uuid!) {
              cultivar: cultivars_by_pk(id: $id) {
                id
                name
                others
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.cultivar;
      } catch (e) {
        console.error(e);
      }
    },

    toCapitalize(val) {
      if (val && val.length >= 2) return val.charAt(0).toUpperCase() + val.slice(1);
      return val
    }
  },
};
</script>

<template>
  <div>
    <h1 class="mb-5">Espécie e cultivares</h1>

    <v-card class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.name"
              label="Cultivar"
              :rules="validations.name"
              outlined
              :disabled="user.role !== 'manager' && id !== 'new'"
              persistent-placeholder
              @change="(val) => form.name = toCapitalize(val)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="form.others"
              label="Outros nomes"
              :disabled="user.role !== 'manager' && id !== 'new'"
              outlined
              persistent-placeholder
            />
          </v-col>
        </v-row>
        <div class="d-flex">
          <v-spacer />
          <v-btn v-if="user.role === 'manager' || (id === 'new' && user.role === 'consultant')" 
            color="primary" plain class="mr-2" to="/cultivares">Cancelar</v-btn>
          <v-btn v-if="user.role === 'manager' || (id === 'new' && user.role === 'consultant')" 
            color="success" :loading="loading" :disabled="loading" @click="save"> Salvar </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
